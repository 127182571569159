import hlsPlayer from './index.vue'

hlsPlayer.install = function (Vue) {
  Vue.component(hlsPlayer.name, hlsPlayer)
}

if (typeof window !== 'undefined' && window.Vue) {
  window.hlsPlayer = hlsPlayer;
  window.Vue.use(hlsPlayer);
}

export default hlsPlayer
